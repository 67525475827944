
export function header(){
    if(document.querySelector('.navbar-nav')){
        let currentPage = window.location.pathname.replace('-', ' ').toLowerCase();
        let menuItems = document.querySelectorAll('.dropdown-item');

        /* menu with current active page is open by default */
        menuItems.forEach((item) => {
            if(currentPage.indexOf(item.innerHTML.toLowerCase()) != -1){
                // item.classList.add('active')
                item.parentElement.classList.add('show')
                item.parentElement.parentElement.classList.add('show')
                item.parentElement.parentElement.querySelector('.dropdown-toggle').setAttribute('aria-expanded', true)
            }
        })
    }
}

export default header