/*
 * @title App
 * @description Application entry point
 */

// Modules
import header from '../modules/header/header';
import imageSlider from '../modules/image-slider/image-slider';
import InitializeViewer from '../modules/ViewerJs/ViewerJs';

document.addEventListener('DOMContentLoaded', function() {
  imageSlider();
  InitializeViewer();
  header();
})

