export function imageSlider() {
  $(document).ready(function(){
    if(document.querySelector('.image-slider')){
      let slideIndex = 1;
      let counterElement = document.querySelector('.js-image-slider__current')
      showSlides(slideIndex);
      
      // Next/previous controls
      function plusSlides(n) {
        showSlides(slideIndex += n);
      } 
      // Thumbnail image controls
      function currentSlide(n) {
        showSlides(slideIndex = n);
      }
      let prevElement = document.querySelector('.js-image-slider-prev')
      let nextElement = document.querySelector('.js-image-slider-next')
      prevElement.addEventListener('click', () => plusSlides(-1));
      nextElement.addEventListener('click', () => plusSlides(1));
      
      function showSlides(n) {
        let i;
        let slides = document.getElementsByClassName("image-slider__slide");
        if (n > slides.length) {slideIndex = 1}
        if (n < 1) {slideIndex = slides.length}
        for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
        }
        slides[slideIndex-1].style.display = "block";
        counterElement.innerHTML = slideIndex + '/'
      }

    }
  });
}

export default imageSlider;
