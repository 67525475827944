/**
 * ViewerJs
 */


import 'viewerjs/dist/viewer.css';

import Viewer from 'viewerjs';

export function InitializeViewer() {

// View an image.

var artWorks = $(".artwork-img");
artWorks.on("click", function(e){
    const viewer = new Viewer(e.target, {
      backdrop: true,
      button:true,
      title: false,
      navbar: false,
      toolbar: false,
      transition: false,
  })
  viewer.show();
});
}

export default InitializeViewer;

